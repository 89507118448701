import React, { useEffect } from 'react';
import { Settings } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import { startCatalogos } from '../../app/store/catalagos/thunks';
import { Customers } from './customers/Customers';
import { Dashboard } from './dashboards/Dashboard';
import { Employees } from './employees/Employees';
import { Finance } from './finance/Finance';
import { Global } from './globals/Global';

import { OrderClose } from './orderClose/OrderClose';

import { OrderTracking } from './orderTracking/OrderTracking';
import { Parts } from './parts/Parts';

import { Profile } from './profile/Profile';

import { Warehouse } from './warehouse/Warehouse';
import { NewOrder } from './orderNew/NewOrder';
import { Sales } from './sales/Sales';
import { Report } from './reportes/Report';
import { Invoicing } from './invoicing/Invoicing';
import { OrderDeparture } from './orderDeparture/OrderDeparture';



export const AppRoutes = () => {
    const { usuario, nombre, puesto, avatar, status } = useSelector(state => state.usuario);

    // Carga inicial de catalogos
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(startCatalogos())
    }, [])


    // console.log(status);


    return (
        <>
            <Routes>


                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/newOrder" element={<NewOrder />} />
                <Route path="/trackingOrder" element={<OrderTracking />} />
                <Route path="/closeOrder" element={<OrderClose />} />
                <Route path="/parts" element={<Parts />} />
                <Route path="/sales" element={<Sales />} />
                <Route path="/trackingOrder/:folio" element={<OrderTracking />} />
                <Route path="/closeOrder/:folio" element={<OrderClose />} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/warehouse" element={<Warehouse />} />
                <Route path="/warehouse/:solProducts" element={<Warehouse />} />
                <Route path="/warehouse/:settings" element={<Warehouse />} />
                <Route path="/finance" element={<Finance />} />
                <Route path="/finance/:settings" element={<Finance />} />
                <Route path="/finance/:settings:newCort" element={<Finance />} />
                <Route path="/reports" element={<Report />} />
                <Route path="/invoicing" element={<Invoicing />} />
                <Route path="/orderDeparture/:folio" element={<OrderDeparture />} />
                <Route path="/orderDeparture/:folio/:vista" element={<OrderDeparture />} />
          
                <Route path="/profile" element={<Profile />} />
                <Route path="/settings" element={<Global />} />
               
                {/* <Route path="/logs" element={<Logs />} /> */}

                <Route path='/*' element={<Navigate to='/dashboard' replace></Navigate>}/>


                {/* Principales */}

            </Routes>
        </>
    )
}
